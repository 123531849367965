<template>
  <v-row no-gutters>
    <v-col class="mr-2">
      <defaultFieldTypes
        :templateContent="result"
        field="c_address"
        :fieldAttributes="result.fields['c_address']"
        @input="checkvalue('c_address', ...arguments)"
        :value="result.valueLine.c_address"
      ></defaultFieldTypes>
    </v-col>
    <v-col class="mr-2">
      <defaultFieldTypes
        :templateContent="result"
        field="c_protocol"
        :fieldAttributes="result.fields['c_protocol']"
        @input="checkvalue('c_protocol', ...arguments)"
        :value="result.valueLine.c_protocol"
      ></defaultFieldTypes>
    </v-col>
    <span class="mr-1 mt-2">:</span>
    <v-col class="mr-2">
      <defaultFieldTypes
        :templateContent="result"
        field="n_port"
        :fieldAttributes="result.fields['n_port']"
        @input="checkvalue('n_port', ...arguments)"
        :value="result.valueLine.n_port"
      ></defaultFieldTypes>
    </v-col>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  data() {
    return { activeProbeIPInfo: {}, activeProbeProtocolInfo: {} };
  },
  props: ["result"],
  created() {
    this.setActiveProbeInfo(this.result.valueLine.n_systemId);
  },
  watch: {
    "result.valueLine.n_systemId": function (newValue) {
      console.log("systemId changed");
      this.setActiveProbeInfo(newValue);
    },
    "result.valueLine.c_address": function (newValue) {
      this.setActiveProbeProtocol(newValue);
    },
    "result.valueLine.c_protocol": function (newValue) {
      this.getActivePort(newValue);
    },
  },
  methods: {
    checkvalue(field, value, text) {
      //console.log("field single row",field, value, text);
      this.$emit("field-value-changed", field, value, text);
    },

    setActiveProbeInfo(value) {
      // let value = this.result.valueLine.n_systemId;
      //let $fieldsObject = $activeProbeLocalClient.closest(".fields");
      /* if (value===""){
    $fieldsObject.find("#n_ipRow").hide();
    resizeForm($fieldsObject);
  }else{
    $fieldsObject.find("#n_ipRow").show();*/
      // let systeminfo = $activeProbeLocalClient.attr("systeminfo");

      let systeminfoObject = JSON.parse(this.systeminfo);
      if (typeof systeminfoObject[value] == "undefined") {
        systeminfoObject[value] = {};
      }
      let ips = Object.keys(systeminfoObject[value]);

      this.$set(this.result.fields.c_address, "associatedOption", ips);
      this.$store.commit("dialogResultChanged", this.result);

      this.activeProbeIPInfo = systeminfoObject[value];
      // let $activeProbeIP = $fieldsObject.find(".activeProbeIP");
      // $activeProbeIP.data("activeProbeIPInfo", systeminfoObject[value]);
      // let activeIp = $activeProbeIP.val();
      // if (activeIp == "") {
      //   activeIp = $activeProbeIP.attr("data-originalvalue");
      // }
      // $activeProbeIP.html(produceOptions(ips, activeIp));
      this.setActiveProbeProtocol(this.result.valueLine.c_address);
      //}
    },

    setActiveProbeProtocol(ip) {
      //let $fieldsObject = $activeProbeIP.closest(".fields");
      // let ip = $activeProbeIP.val();
      let activeProbeIPInfo = this.activeProbeIPInfo;
      // let activeProbeIPInfo = $activeProbeIP.data("activeProbeIPInfo");
      let ipInfo = {};
      if (
        typeof activeProbeIPInfo != "undefined" &&
        typeof activeProbeIPInfo[ip] != "undefined"
      ) {
        ipInfo = activeProbeIPInfo[ip];
      }
      // let $activeProbeProtocol = $fieldsObject.find(".activeProbeProtocol");
      //let peerType = $fieldsObject.find(".peerType").val();
      let peerType = this.result.valueLine.c_type;
      if (peerType == "") {
        peerType = this.result.fields.c_type.associatedOption;
      }
      if (peerType == "DIAMETER") {
        peerType = "Diameter";
      }

      if (
        peerType == "RADIUS_ACCESS" ||
        peerType == "RADIUS_ACCOUNTING" ||
        peerType == "RAD-ACCESS" ||
        peerType == "RAD-DYNAUTH" ||
        peerType == "RAD-ACCT"
      ) {
        peerType = "RADIUS";
      }
      if (peerType == "HTTP_RESPONSE") {
        peerType = "HTTP";
      }
      // let $activeProbeLocalClient = $activeProbeIP
      //   .closest(".fields")
      //   .find(".activeProbeLocalClient");

      let mappingType = "peerType";

      let protocolMapping =
        this.result.fields["n_systemId"]["fieldAttrInput"]["protocolMapping"];

      // let protocolMapping = $activeProbeLocalClient.attr(
      //   "data-protocolMapping"
      // );
      if (typeof protocolMapping != "undefined") {
        let protocolMappingObject = JSON.parse(protocolMapping);
        if (typeof protocolMappingObject[peerType] != "undefined") {
          mappingType = protocolMappingObject[peerType];
        }
      }

      let protocols = {};
      // let typesMappingArray = $activeProbeLocalClient.attr(
      //   "data-typesMappingArray"
      // );
      let typesMappingArray =
        this.result.fields["n_systemId"]["fieldAttrInput"]["typesMappingArray"];
      if (typeof typesMappingArray != "undefined") {
        let typesMappingObject = JSON.parse(typesMappingArray);
        if (typeof typesMappingObject[mappingType] != "undefined") {
          typesMappingObject[mappingType].forEach(function (value) {
            if (typeof ipInfo[value] != "undefined") {
              for (let [protocolType, protocolArray] of Object.entries(
                ipInfo[value]
              )) {
                if (typeof protocols[protocolType] != "undefined") {
                  protocols[protocolType] =
                    protocols[protocolType].concat(protocolArray);
                } else {
                  protocols[protocolType] = protocolArray;
                }
              }
            }
          });
        }
      } else {
        if (typeof ipInfo[peerType] != "undefined") {
          protocols = ipInfo[peerType];
        }
      }

      let protocolArray = Object.keys(protocols);
      console.log(protocolArray);
      this.$set(
        this.result.fields.c_protocol,
        "associatedOption",
        protocolArray
      );
      this.$store.commit("dialogResultChanged", this.result);
      // let activeProtcol = $activeProbeProtocol.val();

      // if (activeProtcol == "") {
      //   activeProtcol = $activeProbeProtocol.attr("data-originalvalue");
      // }
      this.activeProbeProtocolInfo = protocols;
      // $activeProbeProtocol.data("activeProbeProtocolInfo", protocols);
      // $activeProbeProtocol.html(produceOptions(protocolArray, activeProtcol));
      this.getActivePort(this.result.valueLine.c_protocol);
    },
    getActivePort(protocolValue) {
      //let protocolValue = $activeProbeProtocol.val();
      if (typeof protocolValue != "undefined") {
        // let $fieldsObject = $activeProbeProtocol.closest(".fields");
        // let $activeProbePort = $fieldsObject.find(".activeProbePort");
        let protocols = this.activeProbeProtocolInfo;
        // let protocols = $activeProbeProtocol.data("activeProbeProtocolInfo");

        let portsArray = [];
        if (typeof protocols[protocolValue] != "undefined") {
          portsArray = protocols[protocolValue];
        }
        // let activePort = $activeProbePort.val();

        // if (activePort == "") {
        //   activePort = $activeProbePort.attr("data-originalvalue");
        // }
        this.$set(this.result.fields.n_port, "associatedOption", portsArray);
        this.$store.commit("dialogResultChanged", this.result);
        // $activeProbePort.html(produceOptions(portsArray, activePort));
        // resizeForm($fieldsObject);
      }
    },
  },
  computed: {
    systeminfo() {
      return this.result.fields.n_systemId.fieldAttrInput.systeminfo;
    },
  },
};
</script>
